<template>
	<component
		:is="wrapperComponent.is"
		v-bind="wrapperComponent.attrs"
	>
		<bg-input
			v-bind="$attrs"
			v-model="localValue"
			data-testid="input-currency-masking"
			@input="handleInput"
		/>
	</component>
</template>

<script>
import { currencyFormatter } from 'Utils/formatter';
import { BgInput, BgField } from 'bangul-vue';

export default {
	name: 'InputCurrencyMasking',
	components: {
		BgInput,
		BgField
	},
	inheritAttrs: false,

	props: {
		fieldLabel: {
			type: String,
			default: ''
		},
		fieldError: {
			type: Boolean,
			default: false
		},
		fieldMessage: {
			type: String,
			default: ''
		},
		fieldLabelFor: {
			type: String,
			default: ''
		},
		timer: {
			type: Number,
			default: 1000
		},
		value: {
			type: [String, Number],
			default: null
		},
		maxChar: {
			type: [String, Number],
			default: null
		},
		isInputOnly: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			timeout: null,
			localValue: null
		}
	},

	computed: {
		wrapperComponent() {
			if (this.isInputOnly) {
				return { is: 'div', attrs: {} }
			}

			return { is: BgField, attrs: {
				label: this.fieldLabel,
				error: this.fieldError,
				message: this.fieldMessage,
				labelFor: this.fieldLabelFor,
				class: "mb-0",
			}}
		}
	},

	watch: {
		value: {
			handler(newValue) {
				this.localValue = newValue;
				this.handleInput(newValue);
			},
			immediate: true,
		},
	},

	beforeMount() {
		this.manualTrigger();
	},

	methods: {
		inputMoneyAmount(value) {
			return currencyFormatter(value.toString());
		},

		handleInput(e) {
			clearTimeout(this.timeout);

			if (e) {
				const inputString = String(e);
				const rawInput = inputString.split('.').join('');

				let val = inputString;

				if (this.maxChar) {
					if (rawInput.length > this.maxChar) {
						val = rawInput.slice(0, this.maxChar);
					}
				}

				this.$emit('input', this.localValue);

				this.localValue = val.toString().replace(/[^\d.]/g, '');
				this.timeout = setTimeout(() => {
					const value = val.toString().replace(/\D/g, '');

					const temp = value;
					this.localValue = this.inputMoneyAmount(temp);
					this.$emit('input', this.localValue);
				}, this.timer);
			} else {
				this.$emit('input', this.localValue);
			}

		},

		manualTrigger() {
			const nullChecker = this.value || '';
			this.localValue = this.inputMoneyAmount(nullChecker);
		}
	}

}
</script>